import React, { Component } from 'react'

import './WhyOrder.scss'
import eye from '../../assets/ui/eye.png'
import book from '../../assets/ui/book.png'
import car from '../../assets/ui/car.png'
import question from '../../assets/ui/question.png'
import tuuma from '../../assets/ui/tuuma.svg'
import mobile from '../../assets/ui/mobile.svg'
import WP from '../../lib/WP'
import { Link } from '../general/util/Links'
import { connect } from 'kea'
import auth from '../../kea/auth'
import PropTypes from 'prop-types'
import HTML from '../general/util/HTML'

export default @connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ]
})
/**
 * Displays a "Why order TM" box. Displays the number of articles with the 'testipankki' keyword.
 */
class WhyOrder extends Component {
  static propTypes = {
    premiumUser: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      numberOfArticles: 2600
    }
    if (!props.premiumUser) {
      this.getNumberOfTests()
    }
  }

  async getNumberOfTests () {
    const articleData = await WP.getForContext({
      id: 20019, // id of 'testipankki' keyword in production
      taxonomy: 'tags',
      _meta: {
        type: 'term'
      }
    }, {
      per_page: 1,
      _fields: ['id'],
      type: ['post', 'om_digimag_post', 'om_theme_article']
    })
    this.setState({ numberOfArticles: articleData ? articleData.headers['x-wp-total'] : 2600 })
  }

  render () {
    if (this.props.premiumUser) {
      return null
    }
    const blocks = [
      {
        text: 'Näköislehdet vuodesta 1953',
        img: eye,
        hover: 'Pääset lukemaan kaikki koskaan ilmestyneet TM:t',
        url: '/arkisto/'
      },
      {
        text: `${this.state.numberOfArticles} koeajoa ja testiä`,
        img: car,
        hover: 'Jatkuvasti päivittyvässä testipankissa on koko ajan uutta luettavaa',
        url: '/testit/'
      },
      {
        text: '44 digilehteä vuodessa',
        img: book,
        hover: 'Digilehdessä on enemmän luettavaa kuin painetussa lehdessä',
        url: '/digilehdet/'
      },
      {
        text: 'Kysy toimitukselta',
        img: question,
        hover: 'Pääset keskustelemaan toimituksen kanssa kuukausittaisessa Teams-tapaamisessa',
        url: '/avainsana/teams-lukijatapaamiset/'
      },
      {
        text: 'Tekoäly Tuuma',
        img: tuuma,
        hover: 'Kysy mitä vain, Tuuma hakee vastaukset TM:n jutuista',
        url: '/tuuma/'
      },
      {
        text: 'Mobiili&shy;sovellus',
        img: mobile,
        hover: 'Lue TM:ää kännykkäsovelluksella',
        url: '/tekniikan-maailman-uusi-mobiilisovellus-on-nyt-ladattavissa-sovelluskaupoista-lue-kaikkia-sisaltoja-katevasti-sovelluksessa/'
      },
    ]
    return <div styleName="why-order">
      <h3>Miksi tilata TM?</h3>
      <p>TM:n asiantuntijoiden riippumattomat testit tuottavat tietoa, josta on hyötyä.</p>
      <div styleName="blocks">
        {blocks.map((block, key) => <div key={key} styleName="hover-over">
          <div>
            <Link to={{ link: block.url }}>
              <img src={block.img} alt=""/>
              <p><HTML>{block.text}</HTML></p>
            </Link>
          </div>
          <div styleName="hover">{block.hover}
            <div styleName="caret"></div>
          </div>
        </div>
        )}
      </div>
      <Link to={{ link: '/tarjous?utm_source=tmfi_syyt_tilata&utm_medium=syyt_tilata&utm_campaign=muuritarjous' }}>Tilaa Tekniikan Maailma</Link>
    </div>
  }
}
