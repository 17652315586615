import querystring from 'querystring'
import { getEnv } from '@otavamedia/om-component-library/lib/util/env'
import merge from 'lodash/merge'
import get from 'lodash/get'
import isError from 'lodash/isError'
import isEqual from 'lodash/isEqual'
import { transformPostType, transformTaxonomy, transformTerm } from './transformers'
import ArticleModel from '../entities/ArticleModel'
import { createError, errors as err, getType } from './errors'
import DigimagModel from '../entities/DigimagModel'
import IssueNavigationModel from '../entities/IssueNavigationModel'
import { isAdministratorLikeUser, USER_ROLE_READER } from './userManagement'
import ArchiveMagazineModel from '../entities/ArchiveMagazineModel'
import CrossLinkModel from '../entities/CrossLinkModel'
import ImageModel from '../entities/ImageModel'
import AuthorModel from '../entities/AuthorModel'
import flatMap from 'lodash/flatMap'
import HistoryModel from '../entities/HistoryModel'

/**
 * API client for WordPress. Backbone of the application. Caches requests automatically. <br>
 * <strong>Note: WP_Client is already instantiated in WP.js; don't create a new instance.</strong>
 *
 * @param {object} options
 * @param {string} options.url URL that relative requests use
 * @param {object} options.HTTPClient Axios instance
 * @param {object} options.cacheClient Object that exposes get and put methods
 */
class WPClient { // eslint-disable-line camelcase
  nonces = null
  logoutNonce = null
  userRoles = undefined

  constructor ({ url, HTTPClient, cacheClient }) {
    if (!url) {
      throw new TypeError('URL is mandatory')
    } else if (!HTTPClient) {
      throw new TypeError('Unable to perform requests without HTTPClient')
    }

    this.url = url
    this.req = HTTPClient
    this.errorHandlerService = null

    this.loadCacheDriver(cacheClient)
    // this.setUserRoles()
    this.updateNonce()
  }

  async updateNonce () {
    this.nonces = this.req.get(getURL('/om-backend/get-nonce'), {
      withCredentials: true,
    }).then(response => {
      return response.data
    }).catch((e) => {
      console.log(e)
      return { nonce: null, 'logout-nonce': null }
    })
    return this.nonces
  }

  async getLogoutUrl () {
    const logoutNonce = (await this.nonces)['logout-nonce']
    return getURL() + '/wp-login.php?action=logout&_wpnonce=' + logoutNonce
  }

  async getForcedLogoutUrl () {
    const logoutNonce = (await this.nonces)['logout-nonce']

    if (process.env.API_HOST.includes('asteaws.dev')) {
      return getURL() + '/om-backend/logout?_wpnonce=' + logoutNonce + '&redirect_url=' +
        encodeURIComponent('https://omauth-frontend.omauthdev.net/auth/session/end?post_logout_redirect_uri=' +
          getURL() + '&client_id=' + window.om_constants.clientIdDev)
    }
    return getURL() + '/om-backend/logout?_wpnonce=' + logoutNonce + '&redirect_url=' +
      encodeURIComponent('https://tili.otavamedia.fi/auth/session/end?post_logout_redirect_uri=' +
        getURL() + '&client_id=' + window.om_constants.clientId)
  }

  getLoginUrl () {
    return getURL() + '/?option=oauthredirect&app_name=openid&redirect_url=' + window.location.href
  }

  /*
   * Some urls can get fetched multiple times on the same page, cache the promise to avoid multiple requests
   * @private
   */
  static promiseCache = []

  /**
   * Make a request using the promise cache
   * @param url
   * @param params
   * @return {Promise<response>}
   */
  async getWithPromiseCache (url, params) {
    const idx = url + JSON.stringify(params)
    if (!WPClient.promiseCache[idx]) {
      WPClient.promiseCache[idx] = this.get(url, params)
    }
    try {
      return await WPClient.promiseCache[idx]
    } finally {
      WPClient.promiseCache[idx] = null
    }
  }

  /**
   * Error handler "middleware", used by WP_Client methods.
   * Propagates errors to registered error handler, if there is one, and it isn't disabled in the method calling the handler.
   *
   * @param {object} e The error object
   * @param {object} params Configuration object, currently allows skipping error handling
   */
  errorHandler (e, params = {}) {
    params = {
      clientSettings: {},
      ...params,
    }

    const { clientSettings } = params
    if (clientSettings.skipErrorHandling === true) {
      return e
    } else if (this.errorHandlerService) {
      this.reloadIfUnauthorized(e)
      throw this.errorHandlerService(e)
    } else {
      this.reloadIfUnauthorized(e)
      console.warn(e)
      return false
    }
  }

  /**
   * If API response returns 401 or 403, reload the whole page (so that we get an updated nonce, and
   * can redo the API calls)
   *
   * @param e
   */
  reloadIfUnauthorized (e) {
    if (e && e.data && (e.data.status === 403 || e.data.status === 401)) {
      window.location.reload()
    }
  }

  /**
   * Way to extend WP_Client functionality without editing WP_Client itself.
   * Until WP_Client is turned into a library, using it doesn't have much point.
   */
  extend (name, fn) {
    this[name] = fn
  }

  /**
   * WP_Client isn't connected to React in any way, so it has no way
   * of actually showing errors. A separate service is used to actually handle
   * errors.
   *
   * @param {function} x Function that receives errors and acts on them
   */
  setErrorHandlerService (x) {
    this.errorHandlerService = x
  }

  /**
   * After an user logs in, store roles that the user has.
   * Roles are embedded into cached responses, and are used to invalidate
   * the cache, so that a paywall'd article is reloaded.
   *
   * @param {array} roles
   */
  setUserRoles (roles = []) {
    this.userRoles = roles
  }

  /**
   * Load cache middleware
   * @param {object} cacheClient Object that exposes get and put methods
   */
  loadCacheDriver (cacheClient) {
    if (!cacheClient) {
      return false
    }

    this.cache = cacheClient
      ? {
        ...cacheClient,
        get: cacheClient.get,
        put: cacheClient.put,
      }
      : {
        get: () => {
          throw new Error('No cache client')
        },
        put: () => {
          throw new Error('No cache client')
        },
      }

    return true
  }

  /**
   * Get all available post types
   */
  get postTypes () {
    return this._postTypes
  }

  /**
   * Set available post types (after requesting them from WP)
   *
   * @param {object} value
   */
  set postTypes (value) {
    this._postTypes = value
  }

  /**
   * Get all available taxonomies
   */
  get taxonomies () {
    return this._taxonomies
  }

  /**
   * Set available taxonomies (after requesting them from WP)
   *
   * @param {object} value
   */
  set taxonomies (value) {
    this._taxonomies = value
  }

  /**
   * Store a request in localForage cache
   *
   * @param {string} url Request URL with all parameters
   * @param {object} opts Cache settings object
   * @param {any} response Request response
   * @param {number} retries (internal) Retry count. Method will try to cache again after cleaning if cache was full
   */
  async cachePut (url, opts, response, retries = 0) {
    const cacheRes = await this.cache.put(url, opts, response)

    if (isError(cacheRes)) {
      if (cacheRes.name === 'QuotaExceededError') {
        // Disk is full, or user is using a browser that doesn't allow local cache
        // Do some cleanup if disk is indeed full, and then retry

        await this.cache.clean('25%')

        if (retries < 2) {
          this.cachePut(url, opts, response, retries + 1)
        }
      }

      console.warn('Cache put error', cacheRes)
    }
  }

  /**
   * Handle axios errors. Thrown errors flow down to error service
   * Different response statuses result in different errors.
   * https://github.com/axios/axios#handling-errors
   *
   * @param {object} error
   * @throws Will always throw an error
   */
  handleAxiosError (error) {
    if (error.response) {
      switch (error.response.status) {
      case 404:
        throw err.notFoundResponseError(error.response)

      case 403:
        throw err.forbiddenResponseError(error.response)

      case 401:
        throw err.invalidCredentialsError(error.response)

      case 400:
        throw err.badRequestError(error.response)

      default:
        throw err.invalidResponseError(error.response)
      }
    } else if (error.request) {
      throw err.noResponseError(error.request)
    } else {
      // Some unknown error, while setting up the request
      throw createError(getType(error.message), error)
    }
  }

  /**
   * Perform a GET request. If path is relative, request will be made to default domain.
   * Caches requests by default, TTL 30 minutes
   * @param {string} path
   * @param {object} options
   * @param {boolean} noEmbed Don't add _embed parameter
   * @throws If user is disconnected from the network and request can't be retrieved from cache
   */
  async get (path = '', options = {}, noEmbed = false) {
    if (options && (options.cache !== false) && this.userRoles === undefined) {
      // wait for userRoles
      const waitForUser = async () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            if (this.userRoles) {
              resolve(true)
            } else {
              resolve(waitForUser())
            }
          }, 200)
        })
      }
      await waitForUser()
    }
    if (!this.userRoles || !this.userRoles.length) {
      const cookieLevelCheck = document.cookie.match(/asauth_temporary_access=([^;]+)/)
      if (cookieLevelCheck && cookieLevelCheck[1]) {
        this.setUserRoles([USER_ROLE_READER])
      }
    }
    const opts = merge(
      {},
      {
        cache: this.cache
          ? {
            expireAfter: Date.now() + 3600000 / 2,
            userRoles: this.userRoles,
          }
          : false,
        params: noEmbed
          ? {}
          : {
            _embed: 1,
          },
      },
      options
    )
    let extraQs = ''

    // WP acts like an idiot and doesn't handle arrays here.
    if (opts.params.exclude && opts.params.exclude.length) {
      opts.params.exclude = opts.params.exclude.join(',')
    } else {
      delete opts.params.exclude
    }

    // Same with exclude.
    if (opts.params.include && opts.params.include.length) {
      opts.params.include = opts.params.include.join(',')
    } else {
      delete opts.params.include
    }

    // querystring.stringify doesn't handle this correctly
    if (opts.params.type && Array.isArray(opts.params.type)) {
      extraQs = extraQs + opts.params.type.reduce((acc, type) => {
        acc = acc + `&type[]=${type}`
        return acc
      }, '')
      delete opts.params.type
    }

    // or this
    if (opts.params.tax_query) {
      const { tax_query: query } = opts.params

      // const part = (i, key, value) => `&tax_query[${i}][${key}]=${value}`

      const part = (i, key, value) => Array.isArray(value)
        ? value.reduce((acc, v, i2) => (
          acc + `&tax_query[${i}][${key}][${i2}]=${v}`
        ), '')
        : `&tax_query[${i}][${key}]=${value}`
      const string = query.reduce((acc, cond, i) => (
        acc + part(i, 'taxonomy', cond.taxonomy || 'category') +
          part(i, 'field', cond.field || 'term_id') +
          part(i, 'terms', cond.terms)
      ), '')

      extraQs = extraQs + string
      delete opts.params.tax_query
    }

    // The server errors if it sees an unknown param
    if (opts.params.clientSettings) {
      delete opts.params.clientSettings
    }

    // The query strings WP wants are too edgy for any querystring library, parse manually
    if (opts.params.proxysettings) {
      extraQs = extraQs + Object.entries(opts.params.proxysettings).map(([k, v]) => {
        return `&proxysettings[${k}]=${v}`
      }).join('')

      delete opts.params.proxysettings
    }

    const qs = querystring.stringify(opts.params) + extraQs
    const qsPrefix = (path.indexOf('?') > -1) ? '&' : '?'
    const url = (path.indexOf('http') === 0 ? path : getURL(path)) + (qs.length > 1 ? `${qsPrefix}${qs}` : '')

    if (isAdministratorLikeUser(this.userRoles)) {
      opts.cache = false
    }

    if ((this.cache && opts.cache)) {
      const resOrErr = await this.cache.get(url, opts.cache)
      const isErr = isError(resOrErr)

      if (!isErr && resOrErr) {
        const { _meta: meta } = resOrErr

        // If user roles saved with the request are same, allow cached result.
        if (isEqual(meta.userRoles, this.userRoles)) {
          return resOrErr
        }
      } else if (isErr && resOrErr) {
        console.warn('Cache get error', resOrErr)
      }
    }

    // Send cookies only when user has temporary access
    const cookieLevelCheck = document.cookie.match(/asauth_temporary_access=([^;]+)/)
    const withAuthHeader = !options || !options.noCredentials || isAdministratorLikeUser(this.userRoles)
    const useCookies = !!(cookieLevelCheck && cookieLevelCheck[1])
    const nonce = (await this.nonces).nonce
    const response = await this.req.get(url, {
      // don't include a params obj, it may conflict with the qs built above
      // params: opts.params,
      withCredentials: useCookies || (withAuthHeader && nonce),
      headers: (withAuthHeader && nonce) ? { 'X-WP-Nonce': nonce } : {}
    }).catch(this.handleAxiosError.bind(this))

    if (opts.cache && this.cache) {
      await this.cachePut(url, opts.cache, response)
    }

    return response
  }

  /**
   * Perform a POST request. If path is relative, request will be made to default domain.
   * @param {string} path
   * @param {object} data Data in key-value object
   * @param {object} opts
   */
  async post (path = '', data = {}, opts = {}) {
    const url = (path.indexOf('http') === 0 ? path : getURL(path))
    const nonce = (await this.nonces).nonce
    return this.req.post(url, data, { withCredentials: true, ...opts, headers: nonce ? { 'X-WP-Nonce': nonce } : {} })
      .catch(this.handleAxiosError.bind(this))
  }

  /**
   * Helper function for determining which endpoint to use for requesting data
   * Looks in WP_Client.postTypes & WP_Client.taxonomies.
   *
   * @param {string} x
   * @throws If used too early. Can only be called after WP_Client.getInitialData has been called and the results are saved
   */
  getRESTBase (x) {
    if (!this.postTypes || !this.taxonomies) {
      throw this.errorHandler(err.tooEarlyRaceConditionError())
    }

    const { postTypes, taxonomies } = this
    if (postTypes[x]) {
      return postTypes[x].rest_base
    }

    if (taxonomies[x]) {
      return taxonomies[x].rest_base
    }

    // no match, will probably error, but let it slide (for now)
    return x
  }

  /**
   * Get a taxonomy object with a string, usually present in URLs.
   *
   * @param {string} x
   * @throws If used too early. Can only be called after WP_Client.getInitialData has been called and the results are saved
   */
  getTaxFromURLBase (x) {
    if (!this.postTypes || !this.taxonomies) {
      throw this.errorHandler(err.tooEarlyRaceConditionError())
    }

    const { taxonomies } = this

    for (const tax in taxonomies) {
      if (taxonomies[tax].url_base === x) {
        return tax
      }
    }

    return false
  }

  /**
   * Get current user from WP. If nonce is null, don't bother making the request to the server.
   *
   * kea handles the error if user is not logged in
   */
  async getUser () {
    const nonces = await this.nonces
    if (!nonces || !nonces.nonce) {
      throw new Error('no nonce')
    }
    const r = await this.get('/wp-json/wp/v2/users/me', { cache: false })
    const { data } = r

    return data
  }

  /**
   * Refresh user token before it expires
   *
   * @throws If a network related error occurs
   */
  async refreshUserLogin (refreshToken) {
    try {
      const r = await this.post('/wp-json/om/v1/authentication/reauthenticate', {
        token: refreshToken,
      }, {
        // Prevent calling axiosErrorHandler
        validateStatus: (s) => (s >= 200 && s < 300) || s === 400 || s === 401,
      })

      return r
    } catch (e) {
      throw this.errorHandler(e, {})
    }
  }

  /**
   * Log the user out.
   * @todo Actually log the user out
   */
  async userLogout () {
    return {
      status: 200,
      statusText: 'OK',
    }
  }

  /**
   * Clear WP object cache using wp_cache_flush() on the server
   *
   * @throws If a network related error occurs
   */
  async nukeObjectCache () {
    try {
      return await this.post('/wp-json/om/v1/nuke-cache', {}, { withAuthHeader: true })
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get essential data from WP. Required by pretty much everything.
   * After getting the data, it must be manually stored using WP_Client.setTaxonomies & WP_Client.setPostTypes
   *
   * @throws If getting the data fails for whatever reason, initError is thrown
   */
  async getInitialData () {
    try {
      const [postTypesResponse, taxonomiesResponse] = await Promise.all([
        this.getPostTypes(),
        this.getTaxonomies(),
      ])
      const { data: postTypes } = postTypesResponse
      const { data: taxonomies } = taxonomiesResponse

      /* if (postTypes) {
        this.postTypes = postTypes
      } else {
        throw err.initError({ message: 'Failed to load available post types' })
      }

      if (taxonomies) {
        this.taxonomies = taxonomies
      } else {
        throw err.initError({ message: 'Failed to load available taxonomies' })
      } */

      return { postTypes, taxonomies }
    } catch (e) {
      throw this.errorHandler(err.initError({
        message: 'Unable to start application',
        previousError: e,
      }))
    }
  }

  /**
   * Set taxonomies, usually after WP.getInitialData
   */
  setTaxonomies (taxonomies) {
    this.taxonomies = taxonomies
  }

  /**
   * Set post types, usually after WP.getInitialData
   */
  setPostTypes (postTypes) {
    this.postTypes = postTypes
  }

  /**
   * Get all taxonomies from WP using cacheproxy "middleware" endpoint.
   * <br>Client TTL: default
   * <br>Server TTL: 1 hour
   *
   * @param {object} params
   * @throws If a network related error occurs
   */
  async getTaxonomies (params = {}) {
    try {
      const response = await this.get('/wp-json/om/v1/cacheproxy/wp/v2/taxonomies', {
        params: {
          proxysettings: {
            prefetch: true,
            expiry: 3600,
          },
          ...params,
        },
        noCredentials: true,
      })

      if (!response) {
        return false
      }

      const data = Object.entries(response.data)

      if (data.length) {
        response.data = data.reduce((acc, [name, taxonomy]) => {
          acc[name] = transformTaxonomy(taxonomy)

          return acc
        }, {})
      }

      return response
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get all post types from WP using cacheproxy "middleware" endpoint.
   * <br>Client TTL: default
   * <br>Server TTL: 1 hour
   *
   * @param {object} params
   * @throws If a network related error occurs
   */
  async getPostTypes (params = {}) {
    try {
      /*
      const response = await this.get(`/wp-json/om/v1/cacheproxy/wp/v2/types`, {
        params: {
          proxysettings: {
            prefetch: true,
            expiry: 3600,
          },
          ...params,
        },
        noCredentials: true,
      })

      if (!response) {
        return false
      } */
      const response = {
        data: {
          post: {
            description: '',
            hierarchical: 'false',
            name: 'Artikkelit',
            slug: 'post',
            taxonomies: [
              'category',
              'post_tag'
            ],
            rest_base: 'posts',
            url_base: 'posts',

          },
          om_digimag_post: {
            description: '',
            hierarchical: 'false',
            name: 'Digilehden artikkelit',
            slug: 'om_digimag_post',
            taxonomies: [
              'category',
              'post_tag',
              'printmag'
            ],
            rest_base: 'digimag-posts',
            url_base: 'lehti/%printmag%',

          },
          om_ad_article: {
            description: '',
            hierarchical: 'false',
            name: 'Mainosartikkelit',
            slug: 'om_ad_article',
            taxonomies: [
              'om_ad_magazine'
            ],
            rest_base: 'om_ad_article',
            url_base: 'mainoslehti/%om_ad_magazine%',

          },
          om_theme_article: {
            description: '',
            hierarchical: 'false',
            name: 'Teema-artikkelit',
            slug: 'om_theme_article',
            taxonomies: [
              'category',
              'post_tag',
              'om_theme_magazine'
            ],
            rest_base: 'om_theme_article',
            url_base: 'teemalehti/%om_theme_magazine%',

          },
        }
      }
      const data = Object.entries(response.data)

      if (data.length) {
        response.data = data.reduce((acc, [name, postType]) => {
          acc[name] = transformPostType(postType)

          return acc
        }, {})
      }

      return response
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get content from post type(s) using cacheproxy "middleware" endpoint.
   * <br>Client TTL: default
   * <br>Server TTL: 1 hour
   *
   * @param {(string | string[])} type
   * @param {object} params
   * @param {string} endpoint
   * @param {string} parser
   * @throws If a network related error occurs
   */
  async getFromPostTypes (type = 'post', params = {}, endpoint = 'posts', parser = 'default') {
    try {
      const r = await this.get(`/wp-json/om/v1/cacheproxy/wp/v2/${endpoint}`, {
        params: {
          type,
          proxysettings: {
            prefetch: true,
            expiry: 3600,
          },
          ...params,
        },
        noCredentials: true,
      })

      if (!r.data.length) {
        // throw err.noResultsError(r)
      }

      r.data = r.data.map((article) => ArticleModel.create(article, parser))

      return r
    } catch (e) {
      throw this.errorHandler(e, params)
    }
  }

  /**
   * Get terms from taxonomy using cacheproxy "middleware" endpoint
   * <br>Client TTL: default
   * <br>Server TTL: 1 hour
   *
   * @param {string} taxonomy
   * @param {object} params
   * @throws If a network related error occurs
   */
  async getTerms (taxonomy = 'category', params = {}) {
    try {
      const endpoint = this.getRESTBase(taxonomy)
      const r = await this.get(`/wp-json/om/v1/cacheproxy/wp/v2/${endpoint}`, {
        params: {
          proxysettings: {
            prefetch: false,
            expiry: 3600,
          },
          ...params,
        },
        noCredentials: true,
      })

      if (!r.data) {
        throw err.noResultsError(r)
      }

      r.data = r.data.map(transformTerm)
      return r
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get terms from taxonomy using cacheproxy "middleware" endpoint
   * <br>Client TTL: default
   * <br>Server TTL: 1 hour
   *
   * @param {string} taxonomy
   * @throws If a network related error occurs
   */
  async getTermId (taxonomy = 'category', params = {}) {
    try {
      const r = await this.get(`/wp-json/om/v1/cacheproxy/wp/v2/tags?slug=${taxonomy}`, {
        params: {
          proxysettings: {
            prefetch: false,
            expiry: 3600,
          },
          ...params,
        },
        noCredentials: true,
      })

      if (!r.data.length) {
        throw err.noResultsError(r)
      }
      r.data = r.data.map(transformTerm)
      return r
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get menu using menu slug
   * <br>Client TTL: default
   *
   * @param {string} slug
   * @param {object} params
   * @throws If a network related error occurs
   */
  async getMenu (slug = '2-0-ylanavigaatio', params = {}) {
    try {
      return await this.get(`/wp-json/om/v1/menu/${slug}`, {
        cache: {
          expireAfter: Date.now() + 3600 * 1000 * 3,
          acceptExpired: true,
        },
        noCredentials: true,
        ...params,
      })
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get appropriate content for context object. Usually used with application view object.
   * <br>Client TTL: depends on method used
   *
   * @param {object} context
   * @param {object} params
   * @throws If a network related error occurs
   */
  async getForContext (context = {}, params = {}) {
    if (isTerm(context)) {
      const { id: termId, taxonomy } = context
      const termRESTBase = this.getRESTBase(taxonomy)

      return this.getFromPostTypes(params.digimagOnly ? ['om_digimag_post', 'om_theme_article'] : ['post', 'om_digimag_post', 'om_theme_article'], {
        [termRESTBase]: [termId],
        ...params,
      })
    } else if (isTaxonomy(context)) {
      return this.getTerms(context.slug, params)
    } else if (isPostType(context)) {
      return this.getFromPostTypes(context.slug, params)
    } else if (isPost(context)) {
      throw this.errorHandler(err.invalidContextError({ reason: 'Post object isn\'t supported' }))
    } else {
      console.log('Invalid context: ' + JSON.stringify(context))
      return null
    }
  }

  /**
   * Get comments for post, by id
   * <br>Client TTL: 0
   *
   * @param {number} id
   * @throws If a network related error occurs
   */
  async getComments (id) {
    try {
      const r = await this.get('/wp-json/wp/v2/comments', {
        noCredentials: true,
        params: { post: id, order: 'asc', per_page: 100 },
        cache: false
      },
      true)

      if (!r.data) {
        throw err.unableToLoadComments({ data: r.data })
      }

      return r.data
    } catch (e) {
      return []
    }
  }

  /**
   * Get ids of authors (users who work for TM)
   * <br>Client TTL: 0
   *
   * @throws If a network related error occurs
   */
  async getAuthors () {
    try {
      const r = await this.get('/wp-json/om/v1/authors', {
        noCredentials: true,
      })

      if (!r.data) {
        return []
      }

      return r.data
    } catch (e) {
      return []
    }
  }

  /**
   * Get author
   *
   */
  async getAuthor (id) {
    try {
      const r = await this.get('/wp-json/wp/v2/users/' + id, {
        noCredentials: true,
      })

      if (!r.data) {
        return {}
      }

      return new AuthorModel(r.data)
    } catch (e) {
      return {}
    }
  }

  async getAuthorPosts (id, commentatorId, oldestDate) {
    try {
      const r = await this.get('/wp-json/om/v1/authors/' + id + '/' + commentatorId + '/' + oldestDate, {
        noCredentials: true,
      })

      return flatMap(r.data || [[]], ar => ar.map(article => ArticleModel.create(article)))
    } catch (e) {
      return {}
    }
  }

  /**
   * Add a comment to a post
   *
   * @param {string} message
   * @param {number} id Post id
   * @throws If a network related error occurs
   */
  async postComment (message, id) {
    try {
      const response = await this.post('/wp-json/wp/v2/comments', {
        post: id,
        content: message,
      }, { withAuthHeader: true })
      return response.data
    } catch (e) {
      return e.data.data
    }
  }

  /**
   * Get post data from WP using an URL
   * <br>Client TTL: default
   *
   * @param {string} url
   * @param {object} params
   * @param {boolean} validateUserState
   * @throws If a network related error occurs
   */
  async getForURL (url = '', params = {}, validateUserState = false) {
    try {
      const cleanUrl = url.replace(/(&post_format=[a-z]+)|(&_thumbnail_id=[-0-9]+)|(&preview_id=[0-9]+)|(&preview_nonce=[0-9a-z]+)/g, '')
      const r = await this.getWithPromiseCache('/wp-json/resolver/v1/resolve', {
        params: {
          ...params,
          url: cleanUrl,
        },
        cache: false
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      if (r.data && r.data.error) {
        throw createError('RESOLVER_POST_NOT_FOUND', r.data)
      }

      return { ...r, data: ArticleModel.create(r.data, 'default', validateUserState) }
    } catch (e) {
      console.log('Error getting article ' + url)
      console.log(e)
      throw this.errorHandler(e)
    }
  }

  /**
   * Get most popular posts in a category
   * <br>Client TTL: default
   *
   * @param {string} category Category slug
   * @param {boolean} paid Show only paid posts
   * @param {boolean} top20 Load up to 20 posts
   * @throws If no popular posts are found
   */
  async getMostPopular (category = '', paid = false, top20 = false) {
    try {
      const r = await this.getWithPromiseCache('/wp-json/om/v1/popular/' + (paid ? 'paid/' : '') + (top20 ? 'top20/' : '') + category,
        {
          cache: this.cache
            ? {
              expireAfter: Date.now() + 600000 / 2,
              userRoles: this.userRoles,
            }
            : false,
          params: { // will work as queryString
            _embed: 1,
          },
          noCredentials: true
        })

      const data = { ...r.data }
      if (!data) {
        throw err.invalidResponseError({ data })
      }

      Object.keys(data).forEach(timePeriod => {
        data[timePeriod] = (data[timePeriod] || []).map(article => ArticleModel.create(article, 'raw'))
      })
      return data
    } catch (e) {
      throw this.errorHandler(err.popularLoadError({ previousError: e }))
    }
  }

  /**
   * Get most commented psots
   * <br>Client TTL: default
   *
   */
  async getMostCommented () {
    try {
      const r = await this.getWithPromiseCache('/wp-json/om/v1/popular',
        {
          cache: this.cache
            ? {
              expireAfter: Date.now() + 600000 / 2,
              userRoles: this.userRoles,
            }
            : false,
          params: { // will work as queryString
            _embed: 1,
          },
          noCredentials: true
        })

      const data = r.data
      if (!data) {
        return []
      }

      return (data || []).map(article => ArticleModel.create(article))
    } catch (e) {
      return []
    }
  }

  /**
   * Get most popular paid posts in four latest magazines
   * <br>Client TTL: default
   *
   * @throws If no popular posts are found
   **/
  async getMostPopularList () {
    try {
      const r = await this.getWithPromiseCache('/wp-json/om/v1/popular/digimags', { noCredentials: true })

      const data = { ...r.data }
      if (!data) {
        throw err.invalidResponseError({ data })
      }

      return Object.values(data).map(article => ArticleModel.create(article, 'raw'))
    } catch (e) {
      console.log(e)
      throw this.errorHandler(err.popularLoadError({ previousError: e }))
    }
  }

  /**
   * Get shortcuts for a category (by id) or for front page
   * <br>Client TTL: default
   *
   * @param {number} id Category id
   * @throws If a network related error occurs
   */
  async getShortcuts (id) {
    try {
      if (id) {
        const r = await this.get(`/wp-json/om/v1/category/${id}`, {
          noCredentials: true,
        })
        const { shortcuts } = r.data
        return shortcuts.map((article) => ArticleModel.create(article))
      }

      const r = await this.get('/wp-json/om/v1/frontpage/shortcuts', {
        noCredentials: true,
      }, true)
      const shortcuts = r.data
      return shortcuts.map((article) => ArticleModel.create(article))
    } catch (e) {
      return []
    }
  }

  /**
   * Get the latest articles
   *
   * @param {number} category Only get articles in this category
   * @param {number} page Page number
   * @param {[number]} exclude Posts to exclude
   * @throws If a network related error occurs
   */
  async getLatest (category = 0, page = 1, exclude = []) {
    try {
      const params = {
        per_page: 20,
        orderby: 'date',
        order: 'desc',
        page,
        type: ['post', 'om_digimag_post', 'om_theme_article'],
        exclude
      }
      if (category) {
        params.categories = category
      }
      const r = await this.get('/wp-json/wp/v2/posts/', {
        params,
        noCredentials: true,
      })
      const articles = r.data
      return articles.map((article) => ArticleModel.create(article))
    } catch (e) {
      return []
    }
  }

  /**
   * Get editors picks
   * <br>Client TTL: default
   *
   * @throws If no editor picks are found
   */
  async getEditorsPicks () {
    try {
      const r = await this.get('/wp-json/om/v1/frontpage/editorialPicks', {
        noCredentials: true,
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      const editorialPicks = (r.data || []).map(x => ({ item_title: false, item: ArticleModel.create(x) }))

      // Prevent crashing if nothing is selected
      if (!editorialPicks || !editorialPicks.length) {
        return []
      }

      return editorialPicks
    } catch (e) {
      throw this.errorHandler(err.editorsPicksLoadError({ previousError: e }))
    }
  }

  async getLatestTests () {
    try {
      const r = await this.get('/wp-json/om/v1/frontpage/latestTests', {
        noCredentials: true,
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      const latestTests = (r.data || []).map(x => ({ item_title: false, item: ArticleModel.create(x) }))

      // Prevent crashing if nothing is selected
      if (!latestTests || !latestTests.length) {
        return []
      }

      return latestTests
    } catch (e) {
      return []
    }
  }

  async getFactCards () {
    const r = await this.get('/wp-json/om/v1/frontpage/facts', {
      noCredentials: true,
    })

    const main = r.data

    // Prevent crashing if nothing is selected
    if (!main || !main.length) {
      return []
    }
    return main
  }

  async getTopPicks () {
    try {
      const r = await this.get('/wp-json/om/v1/toppicks', {
        noCredentials: true,
      })

      const main = r.data

      // Prevent crashing if nothing is selected
      if (!main || !main.length) {
        return []
      }

      return main.filter(x => x).map((item) => {
        return { ...item, article: ArticleModel.create(item.article) }
      })
    } catch (e) {
      throw this.errorHandler(err.editorsPicksLoadError({ previousError: e }))
    }
  }

  /**
   * Get sticky posts in all categories
   * <br>Client TTL: default
   *
   * @throws If no sticky posts are found
   */
  /**
   * Get sticky posts in a category
   * <br>Client TTL: default
   *
   * @param {string} category Category slug
   * @throws If no sticky posts are found
   */
  async getStickyPosts (category = '') {
    try {
      const r = await this.get('/wp-json/om/v1/stickies/' + category, {
        noCredentials: true,
      })

      if (!r.data || !r.data.stickies) {
        throw err.invalidResponseError({ data: r.data })
      }

      return r.data.stickies.map(article => ArticleModel.create(article))
    } catch (e) {
      throw this.errorHandler(err.stickyLoadError({ previousError: e }))
    }
  }

  /**
   * Get most read premium posts
   * <br>Client TTL: default
   *
   * @throws If no premium posts are found
   */
  async getMostReadPaid () {
    try {
      const r = await this.get('/wp-json/om/v1/mostReadPaidArticles', {
        noCredentials: true,
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      return r.data.map(article => ArticleModel.create(article))
    } catch (e) {
      throw this.errorHandler(err.stickyLoadError({ previousError: e }))
    }
  }

  /**
   * Get IDs of posts which made most shopping cart action
   * <br>Client TTL: default
   *
   * @throws If no posts are found
   */
  async getMostShoppingCartAction () {
    try {
      const r = await this.get('/wp-json/om/v1/mostShoppingCartActions', {
        noCredentials: true,
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      return r.data
    } catch (e) {
      throw this.errorHandler(err.stickyLoadError({ previousError: e }))
    }
  }

  /**
   * Get frontpage data
   * <br>Client TTL: default
   *
   * @throws If a network related error occurs
   */
  async getFrontpage () {
    try {
      const r = await this.get('/wp-json/om/v1/frontpage', {
        noCredentials: true,
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      const { main } = r.data
      const { highlights, stickies, frontpage_history_card: history, main_article } = main
      const mapFn = (x) => {
        const { item, item_title } = x // eslint-disable-line camelcase

        return { item_title, item: ArticleModel.create(item) } // eslint-disable-line camelcase
      }

      if (main_article) {
        main_article['image'] = new ImageModel(main_article['image'])
      }
      return {
        highlights: highlights.map(mapFn),
        stickies: stickies.map(x => ArticleModel.create(x)),
        historyCard: history ? new HistoryModel(history) : null,
        main_article
      }
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get liveVideo data
   */
  async getLiveVideo () {
    try {
      const r = await this.get('/wp-json/om/v1/frontpage/liveVideo', {
        noCredentials: true,
        cache: false
      })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      return r.data
    } catch (e) {
      return null
    }
  }

  /**
   * Get category highlight posts
   * <br>Client TTL: default
   *
   * @param {number} id Category id
   * @throws If a network related error occurs
   */
  async getCategoryHighlights (id) {
    try {
      const r = await this.get(`/wp-json/om/v1/category/${id}`, {
        noCredentials: true,
      })
      const { highlights, editorialPicks } = r.data

      return {
        highlights: highlights.map(({ item_title, item }) => ({ // eslint-disable-line camelcase
          item_title, // eslint-disable-line camelcase
          item: ArticleModel.create(item)
        })),
        editorsPicks: editorialPicks.map(x => ({ item_title: false, item: ArticleModel.create(x) })),
      }
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get general sidebar
   * <br>Client TTL: default
   *
   * @throws If a network related error occurs
   */
  /**
   * Get term sidebar
   * <br>Client TTL: default
   *
   * @param {number} termId term id
   * @throws If a network related error occurs
   */
  async getSidebar (termId) {
    try {
      const url = termId ? `/wp-json/om/v1/sidebar/${termId}` : '/wp-json/om/v1/sidebar'
      const r = await this.get(url, {
        noCredentials: true,
      })
      const { sidebar } = r.data

      return {
        items: (sidebar.items || []).map(({ item_title, item }) => ({ // eslint-disable-line camelcase
          item_title, // eslint-disable-line camelcase
          item: item.id ? ArticleModel.create(item) : false,
        })),
        show_poll_in_sidebar: sidebar.show_poll_in_sidebar
      }
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get latest magazine
   * <br>Client TTL: default
   *
   * @param {string} endpoint
   * @throws If a network related error occurs
   */
  async getLatestMagazine (endpoint = 'digimag') {
    try {
      const r = await this.get(`/wp-json/om/v1/${endpoint}/latest`, {
        noCredentials: true,
      })

      return new DigimagModel(r.data)
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get magazine with slug
   * <br>Client TTL: default
   *
   * @param {string} issue Issue slug
   * @param {string} endpoint
   * @throws If a network related error occurs
   */
  async getMagazine (issue = '27B', endpoint = 'digimag', raw = false) {
    try {
      const r = await this.get(`/wp-json/om/v1/${endpoint}/issue/${issue}`)

      // If access to the axios object is required.
      if (raw) {
        r.data = new DigimagModel(r.data)

        return r
      }

      return new DigimagModel(r.data)
    } catch (e) {
      return new DigimagModel({ term: {} })
    }
  }

  async getMagazineYear (year, endpoint = 'digimag') {
    try {
      const r = await this.get(`/wp-json/om/v1/${endpoint}/issuesByYear/${year}`)

      r.data = Object.values(r.data)
      r.data.forEach(mag => {
        mag.coverImage = mag.coverImage ? new ImageModel(mag.coverImage) : null
      })

      return r.data
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  async getThemeMagazines () {
    try {
      const r = await this.getWithPromiseCache('/wp-json/om/v1/thememagazine/issues/')

      return Object.values(r.data).map(mag => ({
        ...mag,
        coverImage: mag.coverImage ? new ImageModel(mag.coverImage) : null
      }))
    } catch (e) {
      return []
    }
  }

  /**
   * Get magazine navigation with slug
   * <br>Client TTL: default
   *
   * @param {string} issue Issue slug
   * @param {string} endpoint
   * @throws If a network related error occurs
   */
  async getMagazineNavigation (issue, endpoint = 'digimag') {
    try {
      const r = await this.get(`/wp-json/om/v1/${endpoint}/issueNavigation/${issue}`)

      return new IssueNavigationModel(r.data)
    } catch (e) {
      return {}
    }
  }

  /**
   * Trigger hit counter in WP. Request is done with WP_Client.get to get more realistic numbers.
   *
   * @param {number} postId
   * @throws If a network related error occurs
   */
  async triggerHitCounter (postId) {
    try {
      const r = await this.get(`/wp-content/plugins/wp-postviews-aste/postviews-shortinit.php?post_id=${postId}`,
        { noCredentials: true })
      return r.data
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Load so called "native ads", sponsored content from another source. Bypasses WP_Client.get, no cache.
   *
   * @throws On any error
   */
  async loadNativeAds () {
    const r = await this.req.get('https://mainos.otavamedia.fi/wp-json/wp/v2/kuvapainike?categories=27')
      .catch(this.handleAxiosError.bind(this))
    return r.data
  }

  /**
   * Load readPeak ads. A bit complicated because we have to first wait for __tfcapi and get a value from it.
   *
   * @throws On any error
   */
  async loadReadPeakAds () {
    // Wait for __tcfapi to appear in global variables
    const waitForTCF = async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (window.__tcfapi) {
            resolve(true)
          } else {
            resolve(waitForTCF())
          }
        }, 200)
      })
    }
    if (!window.__tcfapi) {
      await waitForTCF()
    }
    // Get tcdata from __tcfapi, and then get the actual ad data from ReadPeak
    return new Promise((resolve, reject) => {
      window.__tcfapi('getTCData', 2, async (tcdata, success) => {
        if (success) {
          try {
            const r = await this.req.get('https://app.readpeak.com/ads/get/?l=90&j=1&ads=3&gdpr_consent=' + tcdata.tcString)
            resolve(r.data)
          } catch (e) {
            reject(e)
          }
        }
        reject(Error('tcdata fetch failed'))
      })
    })
  }

  /**
   * Get magazines in timeframe
   * <br>Client TTL: default
   *
   * @param {string} startDate
   * @param {string} endDate
   * @param {string} year
   * @param {int} count max number of magazines to return
   * @throws If a network related error occurs
   */
  async getMagazines (startDate, endDate, year, count = 55) {
    try {
      const r = await this.get('/nakoislehti/wp-json/asmag/v1/issue-groups/', {
        params: {
          start_date: startDate,
          end_date: endDate,
          count,
        }
      })

      return r.data.reduce((issues, issueGroup) => {
        const currentIssue = issueGroup.issues && issueGroup.issues.find(issue => issue.meta.content_format === 'print_replica' || issue.meta.content_format === undefined)
        if (currentIssue) {
          return [
            ...issues,
            {
              ...new ArchiveMagazineModel(currentIssue),
              name: issueGroup.name,
              slug: issueGroup.slug,
            }
          ]
        }
        return issues
      }, []).filter(magazine => magazine.name.includes(year))
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Get archive magazine
   * <br>Client TTL: default
   *
   * @param {string} slug
   * @throws If a network related error occurs
   */
  async getArchivedMagazine (slug) {
    try {
      const r = await this.get(`/nakoislehti/wp-json/asmag/v1/issue-groups/by-slug/${slug}`, {
        params: {
          exclude_resources: '1',
        },
        cache: false,
      })
      const digimag = r.data.issues.find(issue => issue.meta.content_format === 'html5')
      const printmag = r.data.issues.find(issue => issue.meta.content_format === 'print_replica' || issue.meta.content_format === undefined)
      return new ArchiveMagazineModel({
        ...printmag,
        name: r.data.name,
        slug: r.data.slug,
        digimagUrl: digimag ? digimag.permalink : undefined,
        archiveUrl: printmag.permalink
      })
    } catch (e) {
      throw this.errorHandler(e)
    }
  }

  /**
   * Report comment
   *
   * @param {object} comment
   * @param {object} reason
   * @param {*} honeyPot
   */
  async reportComment (comment, reason, honeyPot) {
    try {
      const data = {
        input_1: reason,
        input_4: comment.link,
        input_5: comment.content.rendered,
        input_6: honeyPot,
      }
      return await this.post('/wp-json/gf/v2/forms/1/submissions', data, { withAuthHeader: true })
    } catch (e) {
      return e
    }
  }

  /**
   * Get embed data from Nettix using saved search id
   * <br>Client TTL: default
   *
   * @param {number} searchId
   */
  async getNettixEmbed (searchId) {
    try {
      let url
      // eslint-disable-next-line eqeqeq
      if (parseInt(searchId) == searchId) {
        url = '/wp-json/om/v1/nettix/searchId/' + searchId
      } else {
        url = '/wp-json/om/v1/nettix/searchUrl' + searchId.replace(/(https?:\/\/[^/]+)/i, '')
      }
      const r = await this.get(url, { noCredentials: true })

      if (!r.data) {
        throw err.invalidResponseError({ data: r.data })
      }

      return r.data
    } catch (e) {
      throw this.errorHandler(err.nettixEmbedLoadError({ previousError: e }))
    }
  }

  /**
   * Subscribe email to newsletter
   *
   * @param {string} email
   * @param {string} honeyPot
   * @param {array} list
   */
  async newsletterSubscribe (email, honeyPot, list) {
    try {
      return await this.post('/wp-json/om/v1/newsletter', { email, username: honeyPot, list })
    } catch (e) {
      return e
    }
  }

  async getCrossLinks () {
    const url = '/wp-json/outdoor/uusimmat'
    const result = await this.get(url, { noCredentials: true })
    if (result.data && result.data[0]) {
      return result.data.map((res) => new CrossLinkModel(res)).filter((item) => item.link.indexOf(getURL()) === -1)
    } else {
      return []
    }
  }

  async getNoAdBlockArticles () {
    const r = await this.getWithPromiseCache('/wp-json/om/v1/adblocker', { noCredentials: true })

    return r.data
  }

  async getVideos (page = 1, order = '-', category = null) {
    try {
      const url = 'https://rest.screen9.com/539498/media/meta,embed?count=9&order=' + order + 'created&page=' + page + (category ? '&categoryid=' + category : '')
      const r = await this.req.get(url, {
        headers: { Authorization: 'Basic NTM5NDk4OlY4cXFYc0RWZjRGUHduak5oNTllb3FMOUxaMjFiSW02MFlUYTBBXy03NnM9' }
      })
      return r.data
    } catch (e) {
      console.log(e)
    }
  }

  async getVideoCategories () {
    try {
      const url = 'https://rest.screen9.com/539498/categories?count=20'
      const r = await this.req.get(url, {
        headers: { Authorization: 'Basic NTM5NDk4OlY4cXFYc0RWZjRGUHduak5oNTllb3FMOUxaMjFiSW02MFlUYTBBXy03NnM9' }
      })
      return r.data
    } catch (e) {
      console.log(e)
    }
  }

  async getTotalPoll (id) {
    try {
      const r = await this.get('/wp-json/totalpoll/v4/poll/' + id, { cache: false, noCredentials: true })
      return r.data.data.poll
    } catch (e) {
      console.log(e)
    }
  }

  async submitPoll (pollId, questionId, voteOption) {
    try {
      const postData = {
        totalpoll: {
          choices: {}
        }
      }
      postData.totalpoll.choices[questionId] = voteOption
      const r = await this.post('/wp-json/totalpoll/v4/poll/' + pollId + '/vote',
        postData,
        {})
      return r.data.data.poll
    } catch (e) {
      console.log(e.data)
      return { error: e }
    }
  }

  async submitComment (commentData, file) {
    try {
      if (file) {
        const data = new FormData()
        data.append('file', file)
        const res = await this.post('/wp-json/wp/v2/media', data, {
          headers: { 'Content-Disposition': 'attachment' },
          withAuthHeader: true,
          // Prevent calling axiosErrorHandler
          validateStatus: (s) => (s >= 200 && s < 300) || s === 400 || s === 401,
        })
        commentData.kuva = res.data.id
      }
      const response = await this.post('/wp-json/wp/v2/comments', commentData, { withAuthHeader: true })
      return response.data && response.data.data ? response.data.data : response.data
    } catch (e) {
      console.log(e)
      return e.data.data
    }
  }

  async likeComment (comment, like) {
    try {
      const data = {
        id: comment.id,
        like: !!like
      }
      return (await this.post('/wp-json/om/v1/cards/like', data, {
        withAuthHeader: true,
        validateStatus: (s) => s < 500
      })).data
    } catch (e) {
      return e
    }
  }

  /**
   * Returns a Response object with data and headers.
   * @param params
   * @returns {Promise<response>}
   */
  async getProductCards (params = {}) {
    const r = await this.getWithPromiseCache('/wp-json/om/v1/cards/product', { noCredentials: true, params })
    r.data = r.data.map(x => new ArticleModel(x, 'productcard'))
    return r
  }

  /**
   * Returns a Response object with data and headers.
   * @param params
   * @returns {Promise<response>}
   */
  async getMemoryCards (params = {}) {
    const r = await this.getWithPromiseCache('/wp-json/om/v1/cards/product-memory', { noCredentials: true, params })
    r.data = r.data.map(x => new ArticleModel(x, 'memorycard'))
    return r
  }

  /**
   * Returns a Response object with data and headers.
   * @param params
   * @returns {Promise<response>}
   */
  async getQuestionCards (params = {}) {
    const r = await this.getWithPromiseCache('/wp-json/om/v1/cards/question', { noCredentials: true, params })
    r.data = r.data.filter(x => x['om:postsCardIsLinkedToUrls']).map(x => new ArticleModel(x, 'questioncard'))
    return r
  }

  async getHintaopasCategory (category) {
    try {
      const r = await this.getWithPromiseCache('/wp-json/om/v1/hintaopas/category/' + category, { noCredentials: true })
      return r.data
    } catch (e) {
      return []
    }
  }

  async elasticSearch (searchString, sorting, filter, page = '0', pageSize = '12', category, source) {
    const data = {
      ...filter,
      brand: 'Tekniikan Maailma',
      text: searchString,
      textOperator: 'AND',
      pageSize,
      page,
      sorting
    }
    if (source) {
      data.source = source
    }
    if (category) {
      data.mainCategory = category
    }
    try {
      const r = await this.post(getElasticURL(), data, { withCredentials: false })
      return r.data
    } catch (e) {
      return {
        hits: [],
        pageNumber: 1,
        totalHits: 0,
        totalPages: 1
      }
    }
  }

  async aiSearch (searchString, richie = false) {
    try {
      // throw Error
      const r = await this.get('/wp-json/om/v1/'+(richie ? 'ref/?doklbjsalkdf=' : 'tuuma/?query=') + encodeURIComponent(searchString), {}, true)
      if (r && r.data && !r.data.relevant_questions) {
        r.data.relevant_questions = [
          'Anna vinkkejä Tuuman käyttämiseen'
        ]
      }
      return r.data
    } catch (e) {
      return {
        answer: 'Nyt iski demoefekti! Tuuma ei pysty nyt vastaamaan, yritä hetken päästä uudestaan.',
        chat_history: [],
        question: searchString,
        source_documents: [
        ],
        relevant_questions: [
          'Anna vinkkejä Tuuman käyttämiseen'
        ]
      }
    }
  }

  async aiExamples () {
    try {
      // throw Error
      const r = await this.get('/wp-json/om/v1/frontpage/ai', {
        params: {
          proxysettings: {
            prefetch: false,
            expiry: 3600,
          },
        },
        noCredentials: true,
      })
      return r.data
    } catch (e) {
      return { enabled: false, examples: [] }
    }
  }
}

/**
 * Helper functions for easy checks, relies on transformers adding meta data
 * to objects.
 */

/**
 * Check if context object is a term object
 *
 * @returns {boolean}
 */
const isTerm = ctx => get(ctx, '_meta.type') === 'term'

/**
 * Check if context object is a taxonomy object
 *
 * @returns {boolean}
 */
const isTaxonomy = ctx => get(ctx, '_meta.type') === 'taxonomy'

/**
 * Check if context object is a post type object
 *
 * @returns {boolean}
 */
const isPostType = ctx => get(ctx, '_meta.type') === 'post_type'

/**
 * Check if context object is a post object
 *
 * @returns {boolean}
 */
const isPost = ctx => get(ctx, '_meta.type') === 'post'

/**
 * Check if object is a cached
 *
 * @returns {boolean}
 */
const isCached = ctx => Boolean(get(ctx, '_meta.expireAfter', false))

/**
 * Get full URL to WP
 *
 * @returns {string}
 */
const getURL = (path = '') => (({
  development: (process.env.API_HOST || 'https://wp.tekniikanmaailma.asteaws.dev'),
  production: (process.env.API_HOST || 'https://wp.tekniikanmaailma.fi'),
  test: (process.env.API_HOST || 'http://wpdev.local:8209'),
})[getEnv()]) + path

const getElasticURL = () => (({
  development: 'https://search-otava.fi/search/search',
  // development: 'https://es-search-api.asteaws.dev/search/search',
  production: 'https://search-otava.fi/search/search',
  test: 'https://search-otava.fi/search/search',
})[getEnv()])

export {
  isTerm,
  isTaxonomy,
  isPostType,
  isPost,
  isCached,
  getURL,
}

export default WPClient // eslint-disable-line camelcase
